import React from 'react'
import {useAuth0} from "@auth0/auth0-react"
import {Button} from "react-bootstrap"

const LogoutGumb = () => {
    const {logout}  = useAuth0();
    return (
            <Button onClick={()=>logout()}
            id="logoutbutton" 
            variant="danger" 
            className="btn-margin"
            >
            Odjava
            </Button>
    );
};

export default LogoutGumb
