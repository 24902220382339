const contentData = [
  {
    title: "Unos podataka",
    link: "https://auth0.com/docs/connections",
    description:
      "Različiti podaci se mogu unositi u bazu, npr. katastarske čestice, adrese, kućni brojevi, osobe upisane u katastarski operat, gruntovnicu.",
      style: "blue",
    },
  {
    title: "Vođenje evidencije",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Moguće je preko programa voditi evidenciju o cestama, poljoprivrednom i građevinskom zemljištu ili bilo koji drugi podatak, a koji je povezanom s nekom informacijom u prostoru.",
      style: "blue",
    },
  {
    title: "Sigurnost informacija",
    link: "https://auth0.com/docs/anomaly-detection",
    description:
      "Sigurnost podataka na prvom je mjestu u svemu što radimo. Sigurnosne značajke ugrađene su u sve naše proizvode, usluge i infrastrukturu da bismo zaštitili podatke na svakoj razini.",
    style: "blue",
  },
  {
    title: "Backup podataka",
    link: "https://auth0.com/docs/rules",
    description:
      "Pohrana podataka ili backup podataka služi kao dodatna razina zaštite od gubitka važnih poslovnih podataka.",
      style: "blue",
    },
];

export default contentData;
