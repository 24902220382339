import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";

const LoginGumb = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div>
      <Button
        onClick={() => loginWithRedirect()}
        id="loginbutton"
        variant="primary"
        className="btn-margin"
      >
        Prijava
      </Button>
    </div>
  );
};

export default LoginGumb;
