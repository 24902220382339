import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const Gallery = () => {
  // ubaciti fotke lucice umjesto ovih
  const images = [
    {
      original: "https://i.gyazo.com/d656f4976aa8433206eceeffe52ee9c8.jpg",
      thumbnail: "https://i.gyazo.com/d656f4976aa8433206eceeffe52ee9c8.jpg",
    },
    {
      original: "https://i.gyazo.com/795197facb1b83a69ed6584976d3c0a2.jpg",
      thumbnail: "https://i.gyazo.com/795197facb1b83a69ed6584976d3c0a2.jpg",
      originalTitle: "Cetingrad",
    },
    {
      original: "https://i.gyazo.com/f185f9ac3c5863a47dac76e096d11561.jpg",
      thumbnail: "https://i.gyazo.com/f185f9ac3c5863a47dac76e096d11561.jpg",
    },
  ];
  return (
    <div>
      <ImageGallery items={images} />
    </div>
  );
};

export default Gallery;
