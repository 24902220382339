import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import LogoutGumb from "./logoutgumb";
import LoginGumb from "./logingumb";
import { useAuth0 } from "@auth0/auth0-react";
const MainNav = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Nav className="mr-auto">
      <Nav.Link
        as={RouterNavLink}
        to="/"
        exact
        activeClassName="router-link-exact-active"
      >
        Početna
      </Nav.Link>
      {isAuthenticated && (
        <Nav.Link
          as={RouterNavLink}
          to="/profile"
          exact
          activeClassName="router-link-exact-active"
        >
          Profil
        </Nav.Link>
      )}
      {isAuthenticated && (
        <Nav.Link
          as={RouterNavLink}
          to="/external-api"
          exact
          activeClassName="router-link-exact-active"
        >
          API
        </Nav.Link>
      )}
      {isAuthenticated && (
        <Nav.Link
          as={RouterNavLink}
          to="/karta"
          exact
          activeClassName="router-link-exact-active"
        >
          Karta
        </Nav.Link>
      )}
    </Nav>
  );
};

const AuthNav = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Nav className="justify-content-end">
      {isAuthenticated ? <LogoutGumb /> : <LoginGumb />}
    </Nav>
  );
};

const NavBar = () => {
  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Navbar.Brand as={RouterNavLink} className="logo" to="/" />
        <MainNav />
        <AuthNav />
      </Container>
    </Navbar>
  );
};

export default NavBar;
