import React from "react";
import { FaReact, FaDigitalOcean } from "react-icons/fa";
import contentData from "../utils/content-data";
import { Col, Row } from "react-bootstrap";
import { SiPostgresql, SiQgis } from "react-icons/si";
import Gallery from "./gallery";
const Content = () => {
  return (
    <div className="next-steps my-5">
      <h2 className="my-5 text-center">Mogućnosti</h2>
      <Row className="d-flex justify-content-between">
        {contentData.map((col, i) => (
          <Col key={i} md={6} className="mb-4">
            <h6 className="naslovi mb-3 text-center">
              {col.title}
              <hr />
            </h6>
            <p>{col.description}</p>
          </Col>
        ))}
      </Row>
      <div className="tehnologije">
        <Row className="mt-6">
          <Col md={12} className="mb-4">
            <h2 className="my-5 text-center">Korištene tehnologije</h2>
            <hr />
          </Col>
          <Col md={3} className="mb-4  text-center">
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaReact size={70} style={{ color: "#0066f9" }} />
              <h4 className="mt-4" style={{ color: "black" }}>
                ReactJS
              </h4>
            </a>
            <hr />
          </Col>
          <Col md={3} className="mb-4  text-center">
            <a
              href="https://www.postgresql.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiPostgresql size={70} style={{ color: "#0066f9" }} />
              <h4 className="mt-4" style={{ color: "black" }}>
                PostgreSQL
              </h4>
            </a>
            <hr />
          </Col>
          <Col md={3} className="mb-4  text-center">
            <a
              href="https://qgis.org/en/site/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiQgis size={70} style={{ color: "#0066f9" }} />
              <h4 className="mt-4" style={{ color: "black" }}>
                Qgis
              </h4>
            </a>
            <hr />
          </Col>
          <Col md={3} className="text-center">
            <a
              href="https://www.digitalocean.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDigitalOcean size={70} style={{ color: "#0066f9" }} />
              <h4 className="mt-4" style={{ color: "black" }}>
                DigitalOcean
              </h4>
            </a>
            <hr />
          </Col>
        </Row>
        <Row style={{ marginBottom: "100px" }}>
          <Col>
            <Gallery />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Content;
