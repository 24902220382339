import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Highlight } from "../components";

export const Profile = () => {
  const {user, isAuthenticated} = useAuth0();
  return (
    //popravljen dio gdje je izbacivalo error kad netko nije prijavljen
    isAuthenticated && (
      <Container className="mb-5">
      <Row className="">
      <Col md={8}>
      <h1 className="text-center">Prijavljeni ste kao</h1>
      <hr className="mb-5"/>
      </Col>
      </Row>
<Row className="align-items-center profile-header mb-5 text-center text-md-left">
  <Col md={2}>
    <img src={user.picture}
    alt="Profile"
    className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
    />
  </Col>
  <Col md>
    <h2>
      {user.nickname}
    </h2>
    <p className="lead text-muted">{user.email}</p>
  </Col>
</Row>
<Row>
  <Highlight>{JSON.stringify(user,null,2)}</Highlight>
</Row>
    </Container>
    )

  );
};

export default Profile;
