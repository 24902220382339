import React from "react";

import logo from "../tronbrand.png";
import { useAuth0 } from "@auth0/auth0-react";
const Hero = () => {
  const { user, isAuthenticated } = useAuth0();
  return (
    <div className="text-center my-5">
      {
        // user naslov pocetna prikazi ako je loginan
        isAuthenticated && (
          <div>
            <h1>Dobrodošli</h1>
            <br />
            <h4>{user.email}</h4>
            <hr />
          </div>
        )
      }
      <div className="text-center hero my-5">
        <img
          className="mb-3 app-logo"
          src={logo}
          alt="React logo"
          width="120"
        />
        <h1 className="mb-4">Tron SGIS</h1>
        <p className="lead">
          Geografski informacijski sustav koji u sebi sadrži prostorne podatke.{" "}
          <br />{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tron-usluge.hr/"
          >
            Tron Usluge
          </a>
        </p>
      </div>
    </div>
  );
};

export default Hero;
